<template>
    <div class="callback">
        <div class="center">
            <div class="top">
                <i class="iconfont icon-chenggong"></i>
            </div>
            <div class="res">恭喜！授权成功</div>
            <!-- toWxBasics -->
            <div class="sure" @click="toWxBasics">确定</div>
        </div>
    </div>
</template>

<script>
export default {
    created () {
        let query = JSON.stringify(this.$route.query)
        localStorage.setItem('_authorization', query)
        if (this.$route.query.store_id) {
            // 聚特惠微信授权回调
            this.$axios.post(this.$api.serviceProvider.handleAuthorize, {
                auth_code: this.$route.query.auth_code,
                uniacid: localStorage.getItem('u_id'),
                store_id: this.$route.query.store_id
            })
        } else if (this.$route.query.auth_code) {
            // 微信的回调
            this.$axios.post(this.$api.set.Wxticket, {
                auth_code: this.$route.query.auth_code,
                uniacid: localStorage.getItem('u_id')
            })
        } else if (this.$route.query.authorization_code) {
            // 百度的回调
            this.$axios.post(this.$api.set.baidu.getAccessToken, {
                code: this.$route.query.authorization_code
            })
        } else if (this.$route.query.code) {
            // 百度云盘的回调
            this.$axios.post(this.$api.set.BaiduCloudDisk.handleAuthorize, {
                code: this.$route.query.code,
                uniacid: localStorage.getItem('u_id')
            })
        }
    },
    methods: {
        toWxBasics () {
            window.close()
        }
    }
}
</script>

<style lang="less" scoped>
.callback {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .top {
        text-align: center;
    }

    .icon-chenggong {
        font-size: 50px;
        color: #1dba1d;
        background-color: #fff;
    }

    .res {
        font-weight: 600;
        font-size: 30px;
        margin: 10px 0;
        text-align: center;
    }

    .sure {
        width: 300px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-color: #155bd4;
        color: #fff;
        font-size: 14px;
    }
}
</style>
